html {
    overflow-y: scroll;
    /*overflow-x: hidden;*/
    scroll-behavior: inherit;
    min-height: -webkit-fill-available;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

div#root {
    font-family: 'Poppins', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    padding: 0;
}

button {
    font-family: 'Poppins', sans-serif !important;
}

body {
    margin: 0;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: -webkit-fill-available;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.MuiPhoneNumber-flagButton {
    margin: 0 !important;
    padding: 0 !important;
    min-width: 0 !important;
    /*background-color: rgba(255, 37, 58, 0.01) !important;*/
    /*border: #c1bdbd 1px solid !important;*/
    /*margin-left: -10px !important;*/
}

.wordWrap {
    word-wrap: break-word;
}


sup, sub {
    font-size: small;
}


i {
    color: inherit;
}

p {
    margin: 0;
    padding: 0;
}

.mr1 {
    margin-right: 8px;
}

p.json {
    white-space: pre;
}


.sentence {
    display: block;
    text-transform: lowercase;
}

.sentence::first-letter {
    text-transform: uppercase;
}
