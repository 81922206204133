.pac-container {
    z-index: 40000;
}

.modal {
    z-index: 20;
}

.modal-backdrop {
    z-index: 10;
}
